/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import './src/components/core.scss'
import './src/tailwind-base.css'
import './src/styles.css'

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import "prism-themes/themes/prism-one-dark.min.css"
// import "prism-themes/themes/prism-xonokai.css"
// import "prism-themes/themes/prism-vsc-dark-plus.min.css"
// import "prism-themes/themes/prism-vsc-dark-plus.min.css"
config.autoAddCss = false
